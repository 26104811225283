import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Button.module.scss";

function Button({ classNameForGTM, className, label, href }) {
  return (
    <a
      className={classNames(styles["container"], className, classNameForGTM)}
      href={href}
    >
      {label}
    </a>
  );
}

Button.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  classNameForGTM: PropTypes.string,
};

Button.defaultProps = {
  className: void 0,
  classNameForGTM: void 0,
};

export default Button;
