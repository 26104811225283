import PropTypes from "prop-types";
import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";
import WhitePaper from "../components/white-paper/WhitePaper";

function WhitePaperPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={["jawg maps", "map", "map api", "whitepaper"]}
          location={location}
          metaDescription="Understand what is behind map services and how it works under the hood."
          title="Map Services: Under the Hood"
        />
        <Page>
          <WhitePaper />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

WhitePaperPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default WhitePaperPage;
