import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import Button from "../button/Button";
import HeroHeader from "../hero-header/HeroHeader";
import addI18nContextConsumer from "../i18n/addI18nContextConsumer";
import i18n from "./WhitePaper.i18n.json";
import styles from "./WhitePaper.module.scss";

const renderContent = (content) => {
  return Array.isArray(content)
    ? content.map((txt) =>
        typeof txt === "string" ? <>{txt}</> : <b>{txt.bold}</b>
      )
    : content;
};

const Demo = ({ strings }) => {
  const data = useStaticQuery(graphql`
    query {
      whitepaperThumbnail: file(
        relativePath: { eq: "whitepaper_thumbnail.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 256, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
  return (
    <>
      <GatsbyImage
        alt="Whitepaper thumbnail"
        image={getImage(data.whitepaperThumbnail)}
      />
      <Button
        className={styles["btn"]}
        href="#form"
        label={strings["action"]}
      />
    </>
  );
};

const WhitePaper = ({ strings }) => {
  return (
    <div className={styles["container"]}>
      <HeroHeader
        className={styles["header"]}
        Demo={addI18nContextConsumer(i18n)(Demo)}
        strings={strings["header"]}
      />
      <section className={styles["description"]}>
        <div className={styles["content"]}>
          <h2>{strings["description"]["title"]}</h2>
          {strings["description"]["content"].map((content) => (
            <p>{renderContent(content)}</p>
          ))}
        </div>
      </section>
      <section className={styles["form"]} id="form">
        <iframe
          height="760px"
          src="https://share.hsforms.com/156fXb7hMRYuq1RI_KgYbbw5cgj9"
          title="White Paper form"
          width="100%"
        />
      </section>
    </div>
  );
};

WhitePaper.propTypes = {
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

Demo.propTypes = {
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(WhitePaper);
